import React, { useEffect } from 'react'
import axios from 'axios'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import { GoogleOutlined, LogoutOutlined } from '@ant-design/icons'
import { api } from '../functions/api'
const redirect_uri = process.env.REACT_APP_API_REDIRECT
function Login() {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  useEffect(() => {
    const fetchUserData = async (accessToken) => {
      try {
        const response = await axios.get(
          'https://people.googleapis.com/v1/people/me?personFields=emailAddresses,names',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )

        const userData = response.data
        const filteredEmails = userData.emailAddresses.filter((email) => email.value.endsWith('@asihs.org'))

        if (filteredEmails.length > 0) {
          const email = filteredEmails[0].value
          await sendEmailsToAPI(email, accessToken)
        } else {
          console.log('Aucune adresse e-mail @asihs.org trouvée.')
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur:', error)
      }
    }

    const getAccessTokenFromUrl = () => {
      const params = new URLSearchParams(window.location.hash.substring(1))
      const accessToken = params.get('access_token')
      return accessToken
    }

    const accessToken = getAccessTokenFromUrl()
    if (accessToken) {
      fetchUserData(accessToken)
    }
  }, [])

  const handleGoogleLogin = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=613698822478-1r22ahfcp2u8mgh5jjt7t6ugdc52rjtu.apps.googleusercontent.com&redirect_uri=${encodeURI(
      redirect_uri
    )}&response_type=token&scope=email%20profile%20https://www.googleapis.com/auth/drive.file%20https://www.googleapis.com/auth/calendar`
    window.location.href = googleAuthUrl
  }

  const sendEmailsToAPI = async (email, accessToken) => {
    try {
      const response = await api('login', { Accesstoken: accessToken, Email: email })
      //console.log(response.data)
      if (response.data.success) {
        setCookie('accessToken', accessToken, { path: '/' })
        setCookie('id', response.data.id, { path: '/' })
        setCookie('token', response.data.token, { path: '/' })
        setCookie('cours_fav', response.data.cours_fav, { path: '/' })
      } else {
        return false
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des adresses e-mail à l'API:", error)
    } finally {
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }
  const logOut = function () {
    removeCookie('id')
    removeCookie('token')
  }
  return !cookies['id'] || !cookies['token'] ? (
    <li className="navbar-item flexbox-left" onClick={handleGoogleLogin}>
      <div className="navbar-item-inner flexbox-left">
        <div className="navbar-item-inner-icon-wrapper flexbox">
          <GoogleOutlined />
        </div>
        <span className="link-text">Login</span>
      </div>
    </li>
  ) : (
    <li className="navbar-item flexbox-left" onClick={logOut}>
      <div className="navbar-item-inner flexbox-left">
        <div className="navbar-item-inner-icon-wrapper flexbox">
          <LogoutOutlined />
        </div>
        <span className="link-text">Logout</span>
      </div>
    </li>
  )
}

export default Login
