import { Button } from 'antd'
import axios from 'axios'
import React from 'react'
import { useCookies } from 'react-cookie'

const Test = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav', 'accessToken'])

  const calendarId = 'c_7puegpc8322ujcvjmkskbu3l8s@group.calendar.google.com'

  const addEventToCalendar = async (accessToken) => {
    const event = {
      summary: 'Réunion à Bruxelles',
      //location: 'Bruxelles, Belgique',
      description: 'Discussion sur les projets en cours.',
      start: {
        dateTime: '2025-02-01T09:00:00+01:00', // Date et heure de début à Bruxelles
        timeZone: 'Europe/Brussels', // Spécifie le fuseau horaire
      },
      end: {
        dateTime: '2025-02-01T10:00:00+01:00', // Fin de l'événement
        timeZone: 'Europe/Brussels',
      },
    }

    try {
      const response = await axios.post(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
        event,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      console.log('Événement ajouté au calendrier:', response.data)
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'événement au calendrier:", error)
    }
  }

  return (
    <div>
      <h1>Test</h1>

      <Button onClick={() => addEventToCalendar(cookies.accessToken)}>ajouter un élément au calendrier</Button>
    </div>
  )
}

export default Test
