import React, { useEffect, useState } from 'react'
import { Drawer, Flex } from 'antd'
import { useCookies } from 'react-cookie'
import { api } from '../functions/api'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

dayjs.locale('fr')
dayjs.extend(isoWeek)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

const ModalView = ({ open, setOpen, jeunes }) => {
  const [cookies] = useCookies(['id', 'token', 'cours_fav'])
  const [vacances, setVacances] = useState(null)
  const [highlightedRow, setHighlightedRow] = useState(null)

  useEffect(() => {
    const getVacances = async () => {
      try {
        const response = await api('dates/conges', {
          id: cookies['id'],
          token: cookies['token'],
        })
        setVacances(response.data.vacances)
      } catch (error) {
        console.error('Erreur lors de la récupération des dates:', error)
      }
    }
    getVacances()
  }, [cookies])

  const minDate = dayjs(Math.min(...jeunes.map((jeune) => new Date(jeune.date_entree)))).startOf('isoWeek')
  const maxDate = dayjs(Math.max(...jeunes.map((jeune) => new Date(jeune.date_sortie)))).endOf('isoWeek')

  const semaines = []
  let currentWeek = minDate
  while (currentWeek.isBefore(maxDate) || currentWeek.isSame(maxDate)) {
    semaines.push({
      debut: currentWeek.clone(),
      fin: currentWeek.clone().add(4, 'day'),
    })
    currentWeek = currentWeek.add(1, 'week')
  }

  const isVacances = (semaine) =>
    vacances?.some(
      (vacance) => vacance.annee === semaine.debut.isoWeekYear() && vacance.numero === semaine.debut.isoWeek()
    )

  return (
    <Drawer
      open={open}
      destroyOnClose={true}
      onClose={() => setOpen(false)}
      title="Vision globale des prises en charge"
      width={'100%'}
    >
      <Flex justify="center">
        <table>
          <thead>
            <tr>
              <th>Jeune</th>
              {semaines.map((semaine, index) => (
                <th key={index}>
                  <div
                    style={{
                      writingMode: 'vertical-lr',
                      transform: 'rotate(180deg)',
                      textAlign: 'center',
                    }}
                  >
                    {`du ${semaine.debut.format('DD MMMM')} au ${semaine.fin.format('DD MMMM')}`}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {jeunes.map((jeune, rowIndex) => (
              <tr
                key={jeune.nom}
                style={{
                  height: highlightedRow === rowIndex ? 60 : 10,
                  cursor: 'pointer',
                  transition: 'height 0.3s ease',
                }}
                onClick={() => setHighlightedRow(rowIndex)}
              >
                <td>
                  {jeune.prenom} {jeune.nom}
                </td>
                {semaines.map((semaine, index) => {
                  const debutPriseEnCharge = dayjs(jeune.date_entree).startOf('isoWeek')
                  const finPriseEnCharge = dayjs(jeune.date_sortie).endOf('isoWeek')
                  const semaineDate = semaine.debut

                  let color = 'white'
                  if (semaineDate.isBetween(debutPriseEnCharge, finPriseEnCharge, null, '[]')) {
                    color = isVacances(semaine) ? 'grey' : 'darkblue'
                  }

                  return (
                    <td key={index}>
                      <div
                        style={{
                          backgroundColor: color,
                          width: 25,
                          height: 25,
                          borderRadius: 5,
                          margin: 'auto',
                        }}
                      />
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    </Drawer>
  )
}

export default ModalView
