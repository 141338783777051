import {
  Anchor,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Flex,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Splitter,
  Table,
  Tooltip,
  Typography,
} from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Titre from '../components/Titre'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { api, apiDocx, apiFile, apiPost } from '../functions/api'
import {
  ArrowLeftOutlined,
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  FileAddFilled,
  FileAddOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LineChartOutlined,
  LoadingOutlined,
  PieChartOutlined,
  PlusOutlined,
  ReadOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'

import debounce from 'lodash/debounce'

import Title from 'antd/lib/typography/Title'
import { toast } from 'react-toastify'
import BilanNotesEdit from '../components/BilanNotesEdit'
import BilanVolet from '../components/BilanVolet'
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const Bilan = () => {
  const divRef = useRef(null)
  const { jeuneId, bilanId } = useParams()
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const [jeune, setJeune] = useState(null)
  const [bilan, setBilan] = useState(null)
  const [notes, setNotes] = useState(null)
  const [profs, setProfs] = useState(null)
  const [cours, setCours] = useState(null)
  const [loading, setLoading] = useState(true)
  const [bilanAssocie, setBilanAssocie] = useState(null)
  const [datasBilan, setDatasBilan] = useState([])
  const [isJeuneLoaded, setIsJeuneLoaded] = useState(false)
  const [isBilanLoaded, setIsBilanLoaded] = useState(false)
  const [isNotesLoaded, setIsNotesLoaded] = useState(false)
  const [isCoursLoaded, setIsCoursLoaded] = useState(false)
  const [isProfsLoaded, setIsProfsLoaded] = useState(false)
  const [isAbsencesLoaded, setIsAbsencesLoaded] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [openModalCommentaire, setOpenModalCommentaire] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [profModalCommentaire, setProfModalCommentaire] = useState(null)
  const [coursModalCommentaire, setCoursModalCommentaire] = useState(null)
  const [modalCommentaire, setModalCommentaire] = useState(null)
  const [modalNotes, setModalNotes] = useState([])
  const initialNotes = useRef(modalNotes)
  const [tempNotesToBilan, setTempNotesToBilan] = useState([])
  const [listCours, setListCours] = useState([])
  const [nouveauCommentaire, setNouveauCommentaire] = useState({
    prof: cookies.id,
    volets: [{ id: null, notes: [], commentaire: '' }],
  })
  const [notesFiltrees, setNotesFiltrees] = useState([])
  const [notValidate, setNotValidate] = useState(false)
  const [isTutore, setIsTutore] = useState(false)
  const [isAbsences, setIsAbsences] = useState(false)
  const [absencesDatas, setAbsencesDatas] = useState(null)
  const [chartSelected, setChartSelected] = useState(0)
  const [fileGeneratedLoading, setFileGeneratedLoading] = useState(false)

  const editorRef = useRef(null)
  const pieChartRef1 = useRef(null)
  const pieChartRef2 = useRef(null)
  const barChartRef = useRef(null)
  const lineChartRef = useRef(null)

  const fetchDatas = async () => {
    try {
      // Charger jeune
      const responseJeune = await api(`jeune/find/${jeuneId}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      const leJeune = {
        ...responseJeune.data,
        bilans: responseJeune.data.bilans ? JSON.parse(responseJeune.data.bilans) : [],
      }
      setJeune(leJeune)
      setIsJeuneLoaded(true)

      // Charger bilan
      const responseBilan = await api(`bilans/find/${bilanId}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      const leBilan = {
        ...responseBilan.data,
        datas: responseBilan.data.datas ? JSON.parse(responseBilan.data.datas) : [],
        charts: responseBilan.data.charts ? JSON.parse(responseBilan.data.charts) : [],
      }
      setBilan(leBilan)
      setDatasBilan(leBilan.datas)
      setIsBilanLoaded(true)

      // Charger notes
      const leBilanId = leJeune.bilans.find((b) => b.bilan_id === leBilan.id).id

      //console.log(leJeune.bilans.find((b) => b.id === leBilanId - 1).date)
      const laDate =
        leBilanId > 1 ? dayjs(leJeune.bilans.find((b) => b.id === leBilanId - 1).date).format('YYYY-MM-DD') : 0

      const responseNotes = await api(`carnetliaison/bilan/${jeuneId}/${laDate}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setNotes(responseNotes.data)
      setIsNotesLoaded(true)

      //Charger profs
      const responseProfs = await api(`prof/allSort/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })
      setProfs(responseProfs.data)
      setIsProfsLoaded(true)

      //Charger cours
      const responseCours = await api(`cours/allSort/prof/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })

      setCours([...responseCours.data.items[0], ...responseCours.data.items[1], ...responseCours.data.items[2]])
      setIsCoursLoaded(true)

      const responseAbsences = await api(`absence/statistics/${jeuneId}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setAbsencesDatas(responseAbsences.data)
      setIsAbsencesLoaded(true)
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error)
    } finally {
    }
  }
  const filterNotesProf = (prof) => {
    const toutesLesNotes = [...notes] // Clone du tableau original pour éviter les mutations

    // Filtrer les notes dont le prof_id correspond à prof
    const notesFiltrees = toutesLesNotes.filter((note) => note.prof_id === prof)

    // Trier les notes filtrées par cours_id
    const notesTriees = notesFiltrees.sort((a, b) => {
      const coursIdA = a.cours_id[0] // Récupère la valeur unique dans le tableau cours_id
      const coursIdB = b.cours_id[0] // Même chose pour l'autre note
      return coursIdA - coursIdB // Comparaison croissante
    })
    setNotesFiltrees(notesTriees)
  }
  const filterNotesCours = (cours) => {
    const toutesLesNotes = [...notes] // Clone du tableau original pour éviter les mutations

    // Filtrer les notes dont le prof_id correspond à prof
    const notesFiltrees = toutesLesNotes.filter((note) => JSON.parse(note.cours_id)[0] === cours)

    setNotesFiltrees(notesFiltrees)
  }
  const addNote = (ns) => {
    if (Array.isArray(ns)) {
      const lesNotes = [...modalNotes, ...ns]
      setModalNotes(lesNotes)
    } else {
      const lesNotes = [...modalNotes, ns]
      setModalNotes(lesNotes)
    }
    setTempNotesToBilan([])
  }
  const saveNotes = () => {
    if (JSON.stringify(initialNotes.current) !== JSON.stringify(modalNotes)) {
      updateBilan(modalNotes)
      //logique de sauvegarde

      initialNotes.current = modalNotes
    }
  }
  const handleModalCommentaire = () => {
    filterNotesProf(cookies.id)
    const comm = { prof: cookies.id, volets: [{ id: null, notes: [], commentaire: '' }] }
    setNouveauCommentaire(comm)
    updatedListCours(cookies.id)
    setOpenModalCommentaire(!openModalCommentaire)
    setModalNotes([])
  }
  const closeModalCommentaire = () => {
    const comm = { prof: cookies.id, volets: [{ id: null, notes: [], commentaire: '' }] }
    setNouveauCommentaire(comm)
    setOpenModalCommentaire(false)
    setModalNotes([])
  }
  const saveCommentaire = async () => {
    if (nouveauCommentaire.volets[0].id === null) {
      setNotValidate(true)
      return
    }
    setNotValidate(false)

    try {
      toast.dismiss()
      await toast.promise(
        // Charger jeune
        apiPost(
          `bilans/addCommentBilan/${jeuneId}/${bilanId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { data: nouveauCommentaire }
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
      fetchDatas()
      setOpenModalCommentaire(false)
      setTempNotesToBilan([])
      setModalNotes([])
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error)
    }
  }
  const updateBilan = async (data) => {
    try {
      toast.dismiss()
      await toast.promise(
        // Charger jeune
        apiPost(
          `bilans/updateBilan/${jeuneId}/${bilanId}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { profId: profModalCommentaire, coursId: coursModalCommentaire, data: data }
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error)
    }
  }
  const updatedListCours = (profId) => {
    // Crée une copie initiale du tableau lesCours avec les valeurs de départ
    let lesCours = [{ value: 0, label: 'Commentaire global', text: 'Commentaire global' }, ...cours]

    // Si isTutore est vrai, chercher l'objet ayant groupe = 2 et le mettre en premier
    if (isTutore) {
      const indexGroupe2 = lesCours.findIndex((course) => course.type === 2)
      if (indexGroupe2 !== -1) {
        // Extraire l'objet trouvé
        const groupe2Course = lesCours.splice(indexGroupe2, 1)[0]
        // Insérer l'objet en première position
        lesCours = [groupe2Course, ...lesCours]
      }
    }

    // Récupérer l'objet correspondant au profId dans datasBilan
    const profData = datasBilan.find((item) => item.prof === profId)

    if (profData) {
      // Parcourir lesCours et vérifier si chaque value correspond à un id dans volets
      const updatedCours = lesCours.map((course) => {
        const isDisabled = profData.volets.some((volet) => volet.id === course.value)
        // Si correspondance, on ajoute { disabled: true } à l'objet cours
        return isDisabled ? { ...course, disabled: true } : course
      })

      // Mettre à jour l'état avec le tableau modifié
      setListCours(updatedCours)
    } else {
      setListCours(lesCours)
    }
  }

  const debouncedSaveBilan = debounce((data) => {
    updateBilan(data)
  }, 800)

  const debouncedUpdate = useCallback(
    debounce((value) => {
      setNouveauCommentaire((prevState) => {
        const updatedVolets = prevState.volets.map((volet) => ({
          ...volet,
          commentaire: value,
        }))
        return { ...prevState, volets: updatedVolets }
      })
    }, 500), // Débouncing après 500 ms
    []
  )
  const handleChange = (editor) => {
    const value = editor.getData()
    debouncedUpdate(value) // Appelle la fonction débouncée
  }
  const fetchAbsences = async () => {
    try {
      const response = await api(`absence/statistics/${jeuneId}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setAbsencesDatas(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des données des absences :', error)
    }
  }
  function saveChartAsImage(chartRef) {
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png') // Convertit en Base64
        sendImageToApi(bilanId, imgData)
      })
    }
  }
  const sendImageToApi = async (bilanId, imageBase64) => {
    try {
      // Préparer les données à envoyer
      const data = {
        image: imageBase64, // L'image en Base64
      }

      // Effectuer la requête API
      const response = await apiPost(
        `bilans/addChart/${bilanId}`,
        {
          id: cookies['id'],
          token: cookies['token'],
        },
        data
      )

      if (!response.data.success) {
        throw new Error("Échec de l'envoi de l'image")
      }

      // Réponse de l'API (par exemple, confirmation ou image ID)
      const charts = await response.data.charts
      setBilan({ ...bilan, charts: charts })
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'image:", error)
    }
  }
  const deleteChart = async (image) => {
    try {
      // Préparer les données à envoyer
      const data = {
        image: image,
      }

      // Effectuer la requête API
      const response = await apiPost(
        `bilans/deleteChart/${bilanId}`,
        {
          id: cookies['id'],
          token: cookies['token'],
        },
        data
      )

      if (!response.data.success) {
        throw new Error("Échec de l'envoi de l'image")
      }

      // Réponse de l'API (par exemple, confirmation ou image ID)
      const charts = await response.data.charts
      setBilan({ ...bilan, charts: charts })
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'image:", error)
    }
  }
  const generatePdf = async () => {
    setFileGeneratedLoading(true)
    // Utilisation de toast.promise pour afficher l'état de la promesse
    await toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const response = await apiFile(`bilans/generate/${jeuneId}/${bilanId}/pdf`, {
            id: cookies['id'],
            token: cookies['token'],
          })
          const file = `${jeune?.prenom}_${jeune?.nom}_bilan_numero_${bilanAssocie?.id}`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${file}.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          resolve() // Promesse résolue lorsque le téléchargement est terminé
        } catch (error) {
          console.error('Erreur lors du téléchargement du PDF', error)
          reject(error) // Rejette la promesse en cas d'erreur
        } finally {
          setFileGeneratedLoading(false)
        }
      }),
      {
        pending: 'Génération en cours...', // Message affiché pendant le traitement
        success: 'Le PDF a été généré avec succès !', // Message en cas de succès
        error: 'Erreur lors de la génération du PDF', // Message en cas d'échec
      }
    )
  }

  useEffect(() => {
    fetchDatas()
  }, [])
  useEffect(() => {
    setIsBilanLoaded(false)
    setIsCoursLoaded(false)
    setIsJeuneLoaded(false)
    setIsNotesLoaded(false)
    setIsProfsLoaded(false)
    fetchDatas()
  }, [jeuneId, bilanId])
  useEffect(() => {
    if (isJeuneLoaded && isBilanLoaded && isNotesLoaded && isCoursLoaded && isProfsLoaded && isAbsencesLoaded) {
      const associe = jeune.bilans.find((b) => b.bilan_id === bilan.id)
      setBilanAssocie(associe)
      setIsTutore(jeune.referent_id == cookies.id)
      updatedListCours(cookies['id'])
      setLoading(false) // Passer à `false` une fois tout chargé
    }
  }, [isJeuneLoaded, isBilanLoaded, isNotesLoaded, isProfsLoaded, isCoursLoaded, isAbsencesLoaded])
  useEffect(() => {
    const comm = { ...nouveauCommentaire }
    comm.volets[0].notes = modalNotes
    setNouveauCommentaire(comm)
    if (modalNotes.length > 0) {
      setTempNotesToBilan([]) // Réinitialise chaque fois que modalNotes change
    }
  }, [modalNotes])
  useEffect(() => {
    setTempNotesToBilan([])
  }, [openModalCommentaire])
  useEffect(() => {
    if (isAbsences) {
      fetchAbsences()
    }
  }, [isAbsences])

  const addCommentsNotesColumns = [
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date) => dayjs(date).format('DD/MM/YYYY') },
    {
      title: 'Contexte',
      dataIndex: 'cours_id',
      key: 'cours',
      filters: cours,
      onFilter: (value, record) => JSON.parse(record.cours_id)[0] === value,
      render: (_, record) => {
        const leCours = record.cours_id ? cours.find((c) => record.cours_id.includes(c.value)) : null
        return leCours ? leCours.label : null
      },
    },
    {
      title: 'Commentaire',
      dataIndex: 'commentaire',
      key: 'commentaire',
      render: (html) => (typeof html === 'string' ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : html),
    },
    {
      title: 'Ajouter',
      key: 'action',
      render: (_, record) => <Button icon={<PlusOutlined />} type="primary" onClick={() => addNote(record)} />,
    },
  ]
  const newCommentsNotesColumns = [
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date) => dayjs(date).format('DD/MM/YYYY') },

    {
      title: 'Commentaire',
      dataIndex: 'commentaire',
      key: 'commentaire',
      render: (html) => (typeof html === 'string' ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : html),
    },
    {
      title: 'Ajouter',
      key: 'action',
      render: (_, record) => <Button icon={<PlusOutlined />} type="primary" onClick={() => addNote(record)} />,
    },
  ]
  // Rendu
  if (loading) {
    return (
      <Flex style={{ height: '100vh' }} align="center" justify="center">
        <Spin size="large" />
      </Flex>
    )
  }

  return (
    <div
      ref={divRef}
      style={{
        height: !fullScreen ? '100vh' : null,
        padding: fullScreen ? 0 : '20px 60px',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto 1fr',
        gap: fullScreen ? 0 : '20px 0px',
        transition: 'all 0.2s linear',
        position: fullScreen ? 'absolute' : 'relative',
        zIndex: fullScreen ? 10000 : 1,
        left: 0,
        width: '100%',
      }}
    >
      <Drawer
        open={openModalCommentaire}
        placement="right"
        width="100%"
        title={'Ajouter un commentaire'}
        onClose={closeModalCommentaire}
        destroyOnClose={true}
        extra={
          <Button icon={<SaveOutlined />} type="primary" onClick={saveCommentaire}>
            Sauvegarder
          </Button>
        }
      >
        <Flex vertical gap={5}>
          <Flex justify="space-between">
            <Flex vertical style={{ width: '49%' }}>
              <Title level={5}>Quel prof ?</Title>
              <Select
                style={{ width: '100%' }}
                options={profs}
                value={nouveauCommentaire.prof}
                onChange={(value) => {
                  setNouveauCommentaire({ ...nouveauCommentaire, prof: value })
                  updatedListCours(value)
                  filterNotesProf(value)
                }}
              />
            </Flex>
            <Flex vertical style={{ width: '49%' }}>
              <Title level={5}>Quel cours ?</Title>
              <Select
                style={{ width: '100%' }}
                status={notValidate ? 'error' : false}
                options={listCours}
                value={nouveauCommentaire.volets[0].id}
                onChange={(value, e) => {
                  const comm = { ...nouveauCommentaire }
                  comm.volets[0].id = value
                  setNouveauCommentaire(comm)

                  if (e.type === 4) {
                    setIsAbsences(true)
                    filterNotesCours(value)
                  } else {
                    setIsAbsences(false)
                    filterNotesProf(cookies.id)
                  }
                }}
              />
            </Flex>
          </Flex>

          <Splitter
            style={{
              marginTop: 10,
              marginBottom: 10,
              minHeight: 50,
              border: '1px solid #d9d9d9',
              borderRadius: 5,
            }}
          >
            <Splitter.Panel min="10%" max="90%">
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Les notes du bilan
              </Title>
              <Flex vertical>
                {nouveauCommentaire.volets[0].notes.map((note, index) => (
                  <BilanNotesEdit
                    index={index}
                    key={note.id}
                    note={note}
                    nouveauCommentaire={nouveauCommentaire}
                    setNouveauCommentaire={setNouveauCommentaire}
                  />
                ))}
              </Flex>
            </Splitter.Panel>
            <Splitter.Panel>
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Les notes du carnet
              </Title>
              <Flex vertical gap={10}>
                <Table
                  rowKey="id" // Utiliser un champ unique pour identifier chaque ligne
                  rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys, selectedRows) => {
                      setTempNotesToBilan(selectedRows)
                    },
                    getCheckboxProps: (record) => ({
                      disabled: record.disabled, // Désactiver certaines lignes si nécessaire
                    }),
                  }}
                  columns={addCommentsNotesColumns}
                  dataSource={notesFiltrees}
                  pagination={false}
                />
                <div style={{ padding: 10 }}>
                  <Button
                    disabled={!tempNotesToBilan.length > 0}
                    type="primary"
                    onClick={() => {
                      addNote(tempNotesToBilan)
                    }}
                  >
                    Ajouter {tempNotesToBilan.length} note{tempNotesToBilan.length > 1 ? 's' : ''} au bilan
                  </Button>
                </div>
              </Flex>
            </Splitter.Panel>
          </Splitter>
          {isAbsences ? (
            <Flex vertical>
              <Title style={{ paddingLeft: 10, paddingTop: 10 }} level={5}>
                Ajouter des diagrammes au bilan
              </Title>
              <Splitter
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  minHeight: 50,
                  maxHeight: 600,
                  border: '1px solid #d9d9d9',
                  borderRadius: 5,
                }}
              >
                <Splitter.Panel min="10%" max="90%">
                  <Flex wrap justify="center" align="center" gap={10} style={{ padding: 10 }}>
                    {bilan.charts.map((chart) => (
                      <Card
                        hoverable
                        style={{
                          paddingTop: 10,
                        }}
                        cover={
                          <img
                            style={{ width: 400, margin: 10 }}
                            src={`https://apiinterlude.compagnon.es/api/images/charts${chart}`}
                            key={chart}
                            alt="chart"
                          />
                        }
                        actions={[
                          <DeleteOutlined
                            key="delete"
                            onClick={() => {
                              deleteChart(chart)
                            }}
                          />,
                        ]}
                      />
                    ))}
                  </Flex>
                </Splitter.Panel>
                <Splitter.Panel>
                  <Flex vertical align="center">
                    <Segmented
                      style={{ margin: 10 }}
                      options={[
                        { label: '% globaux détaillés', value: 0, icon: <PieChartOutlined /> },
                        { label: '% globaux regroupés', value: 1, icon: <PieChartOutlined /> },
                        { label: 'Par jour', value: 2, icon: <BarChartOutlined /> },
                        { label: 'Par semaine', value: 3, icon: <LineChartOutlined /> },
                      ]}
                      value={chartSelected}
                      onChange={(value) => {
                        setChartSelected(value)
                      }}
                    />
                    {chartSelected === 0 ? (
                      <Flex vertical align="center">
                        <div ref={pieChartRef1}>
                          <PieChart width={400} height={220}>
                            <Legend verticalAlign="top" height={200} />
                            <Pie
                              data={absencesDatas.globalTypes}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              fill="#8884d8"
                              legendType="circle"
                              startAngle={0}
                              endAngle={180}
                              label
                              animationDuration={100}
                              animationBegin={0}
                            >
                              {absencesDatas.globalTypes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} animationDuration={100} />
                              ))}
                            </Pie>
                          </PieChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(pieChartRef1)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 2 ? (
                      <Flex vertical align="center">
                        <div ref={barChartRef}>
                          <BarChart
                            width={500}
                            height={300}
                            data={absencesDatas.joursTypes}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <Legend />
                            {absencesDatas.globalTypes.map((type) => (
                              <Bar
                                key={type.name}
                                type="monotone"
                                dataKey={type.name}
                                fill={type.color}
                                animationDuration={100}
                              />
                            ))}
                          </BarChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(barChartRef)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 1 ? (
                      <Flex vertical align="center">
                        <div ref={pieChartRef2}>
                          <PieChart width={400} height={220}>
                            <Legend verticalAlign="top" height={200} />
                            <Pie
                              data={absencesDatas.globalGroupes}
                              dataKey="value"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              startAngle={0}
                              endAngle={180}
                              fill="#82ca9d"
                              label
                              animationBegin={0}
                              animationDuration={100}
                            >
                              {absencesDatas.globalGroupes.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} animationDuration={100} />
                              ))}
                            </Pie>
                          </PieChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(pieChartRef2)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : chartSelected === 3 ? (
                      <Flex vertical align="center">
                        <div ref={lineChartRef}>
                          <LineChart
                            width={500}
                            height={300}
                            data={absencesDatas.semainesTypes}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                          >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {absencesDatas.globalTypes.map((type) => (
                              <Line
                                key={type.name}
                                type="monotone"
                                dataKey={type.name}
                                stroke={type.color}
                                animationDuration={100}
                              />
                            ))}
                          </LineChart>
                        </div>
                        <div>
                          <Button onClick={() => saveChartAsImage(lineChartRef)}>
                            Ajouter le graphique au commentaire
                          </Button>
                        </div>
                      </Flex>
                    ) : null}
                  </Flex>
                </Splitter.Panel>
              </Splitter>
            </Flex>
          ) : null}

          <Title level={5}>Le commentaire</Title>
          <CKEditor
            ref={editorRef}
            editor={ClassicEditor}
            config={{
              toolbar: {
                items: [
                  'heading', // Titres
                  'fontSize', // Taille de texte
                  'bold', // Gras
                  'italic', // Italique
                  'underline', // Souligné
                  'strikethrough', // Barré

                  '|',
                  'bulletedList', // Liste non ordonnée (ul)
                  'numberedList', // Liste ordonnée (ol)
                  '|',
                  'alignment', // Alignement de texte
                  'link', // Liens
                  'imageUpload', // Image
                  'undo', // Annuler
                  'redo', // Répéter
                ],
              },
              plugins: [Bold, Italic, Underline, Strikethrough, Font, Paragraph, List, Alignment, Link, Image],
              fontSize: {
                options: ['tiny', 'small', 'default', 'big', 'huge'],
              },
              alignment: {
                options: ['left', 'center', 'right', 'justify'],
              },

              initialData: nouveauCommentaire.volets[0].commentaire,
            }}
            data={nouveauCommentaire.volets[0].commentaire} // Contenu initial
            onReady={(editor) => {
              editorRef.current = editor // Stocke l'instance de l'éditeur
            }}
            onChange={(e, editor) => {
              handleChange(editor)
            }}
          />
        </Flex>
      </Drawer>
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.2) 0 4px 2px -2px',
          backgroundColor: 'white',
          color: '#6a778e',
          padding: 20,
          borderRadius: fullScreen ? 0 : 5,
        }}
      >
        <Flex justify="space-between" align="center">
          <h2 style={{ fontSize: 25, fontWeight: 600 }}>
            {jeune?.prenom} {jeune?.nom} : Bilan n°{bilanAssocie?.id} du{' '}
            {dayjs(bilanAssocie?.date).format('DD/MM/YYYY')}
          </h2>
          <Flex gap={10}>
            {!fullScreen ? (
              <Tooltip title="ajouter un commentaire">
                <Button icon={<FileAddOutlined />} onClick={() => handleModalCommentaire()} shape="circle" />
              </Tooltip>
            ) : null}
            <Tooltip title={fullScreen ? 'mode édition' : 'mode lecture'}>
              <Button
                onClick={() => {
                  setFullScreen(!fullScreen)
                }}
                shape="circle"
                icon={fullScreen ? <FullscreenExitOutlined /> : <ReadOutlined />}
              />
            </Tooltip>
            <Tooltip title="Exporter en pdf">
              <Button
                icon={!fileGeneratedLoading ? <FilePdfOutlined /> : <LoadingOutlined />}
                onClick={generatePdf}
                disabled={fileGeneratedLoading}
                shape="circle"
              />
            </Tooltip>
          </Flex>
        </Flex>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          color: '#6a778e',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0 4px 2px -2px',
          borderRadius: fullScreen ? 0 : 5,

          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 20,
          paddingRight: 20,
          overflow: 'scroll',
          scrollBehavior: 'smooth',
          position: 'relative',
        }}
      >
        {datasBilan
          .sort((a, b) => {
            // Trier datasBilan pour mettre en premier l'objet où prof === jeune.referent_id
            if (a.prof === jeune.referent_id) return -1 // `a` en premier
            if (b.prof === jeune.referent_id) return 1 // `b` en premier
            return 0 // Garder l'ordre pour les autres
          })
          .map((bilanProf) => {
            const prof = profs.find((prof) => prof.value === bilanProf.prof) // Trouver le professeur correspondant

            return (
              <div key={bilanProf.prof} style={{ marginBottom: 20, position: 'relative' }}>
                {/* Nom du professeur (Sticky en haut) */}
                <div
                  style={{
                    fontSize: 22,
                    fontWeight: 600,
                    padding: 13,
                    position: 'sticky',
                    top: 0, // Sticky en haut du parent scrollable
                    textAlign: 'left',
                    zIndex: 20, // Au-dessus des autres éléments sticky
                    backgroundColor: 'white', // Fond blanc pour masquer les éléments en dessous
                  }}
                >
                  <span>{prof ? prof.label : 'Professeur inconnu'}</span>
                </div>

                {/* Volets */}
                <div>
                  {bilanProf.volets
                    .sort((a, b) => {
                      // Trier volets pour mettre en premier l'élément dont id correspond à l'id de leCours avec type === 2
                      const voletCibleId = listCours.find((course) => course.type === 2)?.value

                      if (a.id === voletCibleId) return -1 // `a` en premier
                      if (b.id === voletCibleId) return 1 // `b` en premier
                      return 0 // Garder l'ordre pour les autres
                    })
                    .map((volet, index) =>
                      volet ? (
                        <BilanVolet
                          charts={bilan.charts}
                          key={volet.id}
                          volet={volet}
                          prof={prof}
                          bilanId={bilanId}
                          cours={listCours}
                          coursModalCommentaire={coursModalCommentaire}
                          setCoursModalCommentaire={setCoursModalCommentaire}
                          fullScreen={fullScreen}
                          handleModalCommentaire={handleModalCommentaire}
                          bilanProf={bilanProf}
                          profs={profs}
                          profModalCommentaire={profModalCommentaire}
                          setProfModalCommentaire={setProfModalCommentaire}
                          notes={notes}
                          addNote={addNote}
                          debouncedSaveBilan={debouncedSaveBilan}
                          initialNotes={initialNotes}
                          fetchDatas={fetchDatas}
                          sendImageToApi={sendImageToApi}
                          deleteChart={deleteChart}
                          absencesDatas={absencesDatas}
                        />
                      ) : null
                    )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default Bilan
