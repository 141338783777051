import axios from 'axios'
const urlDefault = process.env.REACT_APP_API_URL
const urlEcole = process.env.REACT_APP_ECOLE_API
export async function api(url, headersToSend) {
  const response = await axios.get(urlDefault + url, {
    headers: headersToSend,
  })
  return response
}
export async function apiPost(url, headersToSend, datasToSend) {
  const response = await axios.post(urlDefault + url, datasToSend, {
    headers: headersToSend,
  })
  return response
}
export async function apiFile(url, headersToSend, datasToSend) {
  const response = await axios.get(urlDefault + url, {
    headers: headersToSend,
    responseType: 'blob',
  })
  return response
}
export async function apiPut(url, headersToSend, datasToSend) {
  const response = await axios.put(urlDefault + url, datasToSend, {
    headers: headersToSend,
  })
  return response
}
export async function apiDelete(url, headersToSend) {
  const response = await axios.delete(urlDefault + url, {
    headers: headersToSend,
  })
  return response
}
export async function apiEcole(type, nom) {
  const limit = 100

  // Construction de la clause WHERE avec search sur les deux champs
  const whereClause = `niveau = "${type}" AND (search(nom_de_l_etablissement,"${nom}") OR search(commune_de_l_etablissement,"${nom}"))`
  const encodedWhereClause = encodeURIComponent(whereClause)
  const dataUrl = `where=${encodedWhereClause}&limit=${limit}`
  const response = await fetch(urlEcole + dataUrl)
  return response
}

export async function validateToken(id, token) {
  try {
    const response = await axios.get(`${urlDefault}login/validateToken`, {
      headers: {
        id: id,
        token: token,
      },
    })
    return response.data // { error: false, message: "Token valide" }
  } catch (error) {
    console.error('Erreur lors de la validation du token:', error)
    return { error: true } // En cas d'erreur
  }
}
/**
 * Ajoute un événement au calendrier Google.
 * @param {string} accessToken - Le jeton d'accès Google OAuth.
 * @param {Object} evenement - Les détails de l'événement à ajouter.
 * @param {string} evenement.summary - Titre de l'événement.
 * @param {string} [evenement.description] - Description de l'événement.
 * @param {Object} evenement.start - Début de l'événement.
 * @param {string} evenement.start.dateTime - Date/heure de début ISO.
 * @param {string} evenement.start.timeZone - Fuseau horaire du début.
 * @param {Object} evenement.end - Fin de l'événement.
 * @param {string} evenement.end.dateTime - Date/heure de fin ISO.
 * @param {string} evenement.end.timeZone - Fuseau horaire de fin.
 * @returns {Promise<string|false>} - L'ID de l'événement ou `false` en cas d'erreur.
 */
export async function ajouterEvenementCalendrier(accessToken, evenement) {
  const calendarId = 'c_7puegpc8322ujcvjmkskbu3l8s@group.calendar.google.com'

  try {
    const response = await axios.post(
      `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
      evenement,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    console.log('Événement ajouté au calendrier:', response.data)
    return response.data.id // Retourne l'ID de l'événement
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'événement au calendrier:", error)
    return false // Retourne false en cas d'erreur
  }
}
