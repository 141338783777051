import React from 'react'
import '../styles/Navbar2.scss'
import { pagesList } from '../datas/pagesList.js'
import { Link } from 'react-router-dom'
import Login from './Login.jsx'
import { LockOutlined } from '@ant-design/icons'
const Navbar2 = () => {
  return (
    <nav id="navbar" style={{ zIndex: 300 }}>
      <ul className="navbar-items flexbox-col">
        {pagesList.map((page) =>
          page.visible && page.position === 'top' ? (
            <li className="navbar-item flexbox-left" key={page.script}>
              <Link className="navbar-item-inner flexbox-left" to={page.script}>
                <div className="navbar-item-inner-icon-wrapper flexbox">{page.icon}</div>
                <span className="link-text">{page.intitule}</span>
              </Link>
            </li>
          ) : null
        )}
      </ul>
      <ul className="navbar-items flexbox-col">
        <li className="navbar-item flexbox-left">
          <a
            href="https://privacypolicy.compagnon.es"
            className="navbar-item-inner flexbox-left"
            target="_blank"
            rel="noreferrer"
          >
            <div className="navbar-item-inner-icon-wrapper flexbox">
              <LockOutlined />
            </div>
            <span className="link-text">Politique de confidentialité</span>
          </a>
        </li>
        {pagesList.map((page) =>
          page.visible && page.position === 'bottom' ? (
            <li className="navbar-item flexbox-left" key={page.script}>
              <Link className="navbar-item-inner flexbox-left" to={page.script}>
                <div className="navbar-item-inner-icon-wrapper flexbox">{page.icon}</div>
                <span className="link-text">{page.intitule}</span>
              </Link>
            </li>
          ) : null
        )}

        <Login />
      </ul>
    </nav>
  )
}

export default Navbar2
